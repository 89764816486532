// File#: _1_percentage-bar
// Usage: codyhouse.co/license
(function() {
  var PercentageBar = function(element) {
    this.element = element;
    this.bar = this.element.getElementsByClassName('js-pct-bar__bg');
    this.percentages = this.element.getElementsByClassName('js-pct-bar__value');
    initPercentageBar(this);
  };

  function initPercentageBar(bar) {
    if(bar.bar.length < 1) return;
    var content = '';
    for(var i = 0; i < bar.percentages.length; i++) {
      var customClass = bar.percentages[i].getAttribute('data-pct-bar-bg'),
        customStyle = bar.percentages[i].getAttribute('data-pct-bar-style'),
        percentage = bar.percentages[i].textContent;
      
      if(!customStyle) customStyle = '';
      if(!customClass) customClass = '';
      content = content + '<div class="pct-bar__fill js-pct-bar__fill '+customClass+'" style="flex-basis: '+percentage+';'+customStyle+'"></div>';
    }
    bar.bar[0].innerHTML = content;
  }

  window.PercentageBar = PercentageBar;

  //initialize the PercentageBar objects
  var percentageBar = document.getElementsByClassName('js-pct-bar');
  if( percentageBar.length > 0 ) {
		for( var i = 0; i < percentageBar.length; i++) {
			(function(i){new PercentageBar(percentageBar[i]);})(i);
    }
	}
}());