// File#: _1_sticky-feature
// Usage: codyhouse.co/license
(function() {
  var StickyFeature = function(element) {
    this.element = element;
    this.contentList = this.element.getElementsByClassName('js-sticky-feature__content-list');
    this.assetsList = this.element.getElementsByClassName('js-sticky-feature__media-list');
    
    if(this.contentList.length < 1 || this.assetsList.length < 1) return;

    this.contentItems = this.contentList[0].getElementsByClassName('js-sticky-feature__content-item');
    this.assetItems = this.assetsList[0].getElementsByClassName('js-sticky-feature__media-item');

    this.titleItems = this.contentList[0].getElementsByClassName('js-sticky-feature__title');
    this.activeSectionClass = 'sticky-feature-current-item';
    this.bindScroll = false;
    this.scrolling = false;
    initStickyFeature(this);
  };

  function initStickyFeature(el) {
    // init observer - detect when feature list enters the viewport and change section
    var observer = new IntersectionObserver(stickyFeatureObserve.bind(el));
    observer.observe(el.contentList[0]);

    // init click on title
    for(var i = 0; i < el.titleItems.length; i++) {
      (function(i){
        el.titleItems[i].addEventListener('click', function(event){
          scrollToSection(el, i);
        });
      })(i);
    }
  };

  function stickyFeatureObserve(entries) {
    if(entries[0].isIntersecting) {
      if(!this.bindScroll) {
        getSelectSection(this); // update selected section
        bindScroll(this); // bind window scroll
      }
    } else if(this.bindScroll) {
      unbindScroll(this); // unbind window scroll
      resetSectionVisibility(this); // reset selected section
    }
  };

  function updateVisibleSection(el) {
    // on scroll, detect which section should be selected
    var self = this;
    if(this.scrolling) return;
    this.scrolling = true;
    window.requestAnimationFrame(function(){
      getSelectSection(self);
      self.scrolling = false;
    });
  };

  function getSelectSection(el) {
    resetSectionVisibility(el); // remove selected class from all sections
    // get the section to select
    var index = [];
    for(var i = 0; i < el.contentItems.length; i++) {
      if(el.contentItems[i].getBoundingClientRect().top <= window.innerHeight/2) index.push(i);
    }
    var itemIndex = (index.length > 0) ? index[index.length - 1] : 0; // select either the first section or the one in the center of the viewport
    selectSection(el, itemIndex);
  };

  function resetSectionVisibility(el) {
    // no section is selected -> remove selected class
    var selectedItems = el.element.getElementsByClassName(el.activeSectionClass);
    while (selectedItems[0]) {
      selectedItems[0].classList.remove(el.activeSectionClass);
    }
  };

  function selectSection(el, index) {
    el.contentItems[index].classList.add(el.activeSectionClass);
    el.assetItems[index].classList.add(el.activeSectionClass);
  };

  function scrollToSection(el, index) {
    // on click - scroll to the selected section
    if(el.assetsList[0].offsetWidth < 1) return;
    window.scrollBy({
      top: el.titleItems[index].getBoundingClientRect().top - window.innerHeight/2 + 10,
      behavior: 'smooth'
    });
  };

  function bindScroll(el) {
    if(!el.bindScroll) {
      el.bindScroll = updateVisibleSection.bind(el);
      window.addEventListener('scroll', el.bindScroll);
    }
  };

  function unbindScroll(el) {
    if(el.bindScroll) {
      window.removeEventListener('scroll', el.bindScroll);
      el.bindScroll = false;
    }
  };

  window.StickyFeature = StickyFeature;

	//initialize the StickyFeature objects
	var stickyFeatures = document.getElementsByClassName('js-sticky-feature');
	if( stickyFeatures.length > 0 ) {
		for( var i = 0; i < stickyFeatures.length; i++) {
			(function(i){new StickyFeature(stickyFeatures[i]);})(i);
		}
	}
}());