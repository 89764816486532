// File#: _1_infinite-scroll
// Usage: codyhouse.co/license
(function() {
  var InfiniteScroll = function(opts) {
    this.options = extendProps(InfiniteScroll.defaults, opts);
    this.element = this.options.element;
    this.loader = document.getElementsByClassName('js-infinite-scroll__loader');
    this.loadBtn = document.getElementsByClassName('js-infinite-scroll__btn');
    this.loading = false;
    this.currentPageIndex = this.element.getAttribute('data-current-page') ? parseInt(this.element.getAttribute('data-current-page')) : 0;
    this.index = this.currentPageIndex;
    initLoad(this);
  };

  function initLoad(infiniteScroll) {
    setPathValues(infiniteScroll); // get dynamic content paths

    getTresholdPixel(infiniteScroll);
    
    if(infiniteScroll.options.container) { // get container of dynamic content
      infiniteScroll.container = infiniteScroll.element.querySelector(infiniteScroll.options.container);
    }
    
    if((!infiniteScroll.options.loadBtn || infiniteScroll.options.loadBtnDelay) && infiniteScroll.loadBtn.length > 0) { // hide load more btn
      infiniteScroll.loadBtn[0].classList.add('sr-only');
    }

    if(!infiniteScroll.options.loadBtn || infiniteScroll.options.loadBtnDelay) {
      if(intersectionObserverSupported) { // check element scrolling
        initObserver(infiniteScroll);
      } else {
        infiniteScroll.scrollEvent = handleEvent.bind(infiniteScroll);
        window.addEventListener('scroll', infiniteScroll.scrollEvent);
      }
    }
    
    initBtnEvents(infiniteScroll); // listen for click on load Btn
    
    if(!infiniteScroll.options.path) { // content has been loaded using a custom function
      infiniteScroll.element.addEventListener('loaded-new', function(event){
        contentWasLoaded(infiniteScroll, event.detail.path, event.detail.checkNext); // reset element
        // emit 'content-loaded' event -> this could be useful when new content needs to be initialized
      infiniteScroll.element.dispatchEvent(new CustomEvent('content-loaded', {detail: event.detail.path}));
      });
    }
  };

  function setPathValues(infiniteScroll) { // path can be strin or comma-separated list
    if(!infiniteScroll.options.path) return;
    var split = infiniteScroll.options.path.split(',');
    if(split.length > 1) {
      infiniteScroll.options.path = [];
      for(var i = 0; i < split.length; i++) infiniteScroll.options.path.push(split[i].trim());
    }
  };

  function getTresholdPixel(infiniteScroll) { // get the threshold value in pixels - will be used only if intersection observer is not supported
    infiniteScroll.thresholdPixel = infiniteScroll.options.threshold.indexOf('px') > -1 ? parseInt(infiniteScroll.options.threshold.replace('px', '')) : parseInt(window.innerHeight*parseInt(infiniteScroll.options.threshold.replace('%', ''))/100);
  };

  function initObserver(infiniteScroll) { // intersection observer supported
    // append an element to the bottom of the container that will be observed
    var observed = document.createElement("div");
    observed.setAttribute('aria-hidden', 'true');
    observed.setAttribute('class', 'js-infinite-scroll__observed');
    observed.setAttribute('style', 'width: 100%; height: 1px; margin-top: -1px; visibility: hidden;');
    infiniteScroll.element.appendChild(observed);

    infiniteScroll.observed = infiniteScroll.element.getElementsByClassName('js-infinite-scroll__observed')[0];

    var config = {rootMargin: '0px 0px '+infiniteScroll.options.threshold+' 0px'};
    infiniteScroll.observer = new IntersectionObserver(observerLoadContent.bind(infiniteScroll), config);
    infiniteScroll.observer.observe(infiniteScroll.observed);
  };

  function observerLoadContent(entry) { 
    if(this.loading) return;
    if(entry[0].intersectionRatio > 0) loadMore(this);
  };

  function handleEvent(event) { // handle click/scroll events
    switch(event.type) {
      case 'click': {
        initClick(this, event); // click on load more button
        break;
      }
      case 'scroll': { // triggered only if intersection onserver is not supported
        initScroll(this);
        break;
      }
    }
  };

  function initScroll(infiniteScroll) { // listen to scroll event (only if intersectionObserver is not supported)
    (!window.requestAnimationFrame) ? setTimeout(checkLoad.bind(infiniteScroll)) : window.requestAnimationFrame(checkLoad.bind(infiniteScroll));
  };

  function initBtnEvents(infiniteScroll) { // load more button events - click + focus (for keyboard accessibility)
    if(infiniteScroll.loadBtn.length == 0) return;
    infiniteScroll.clickEvent = handleEvent.bind(infiniteScroll);
    infiniteScroll.loadBtn[0].addEventListener('click', infiniteScroll.clickEvent);
    
    if(infiniteScroll.options.loadBtn && !infiniteScroll.options.loadBtnDelay) {
      infiniteScroll.loadBtn[0].classList.remove('sr-only');
      if(infiniteScroll.loader.length > 0 ) infiniteScroll.loader[0].classList.add('is-hidden');
    }

    // toggle class sr-only if link is in focus/loses focus
    infiniteScroll.loadBtn[0].addEventListener('focusin', function(){
      if(infiniteScroll.loadBtn[0].classList.contains('sr-only')) {
        infiniteScroll.loadBtn[0].classList.add('js-infinite-scroll__btn-focus');
        infiniteScroll.loadBtn[0].classList.remove('sr-only');
      }
    });
    infiniteScroll.loadBtn[0].addEventListener('focusout', function(){
      
      if(infiniteScroll.loadBtn[0].classList.contains('js-infinite-scroll__btn-focus')) {
        infiniteScroll.loadBtn[0].classList.remove('js-infinite-scroll__btn-focus');
        infiniteScroll.loadBtn[0].classList.add('sr-only');
      }
    });
  };

  function initClick(infiniteScroll, event) { // click on 'Load More' button
    event.preventDefault();
    infiniteScroll.loadBtn[0].classList.add('sr-only');
    loadMore(infiniteScroll);
  };

  function checkLoad() { // while scrolling -> check if we need to load new content (only if intersectionObserver is not supported)
    if(this.loading) return;
    if(!needLoad(this)) return;
    loadMore(this);
  };

  function loadMore(infiniteScroll) { // new conten needs to be loaded
    infiniteScroll.loading = true;
    if(infiniteScroll.loader.length > 0) infiniteScroll.loader[0].classList.remove('is-hidden');
    
    var moveFocus = false;
    if(infiniteScroll.loadBtn.length > 0 ) moveFocus = infiniteScroll.loadBtn[0].classList.contains('js-infinite-scroll__btn-focus');
    // check if need to add content or user has custom load function
    if(infiniteScroll.options.path) {
      contentBasicLoad(infiniteScroll, moveFocus); // load content
    } else {
      emitCustomEvents(infiniteScroll, 'load-new', moveFocus); // user takes care of loading content
    }
  };

  function contentBasicLoad(infiniteScroll, moveFocus) {
    var filePath = getFilePath(infiniteScroll);
    // load file content
    getNewContent(filePath, function(content){
      var checkNext = insertNewContent(infiniteScroll, content, moveFocus);
      contentWasLoaded(infiniteScroll, filePath, checkNextPageAvailable(infiniteScroll, checkNext));
      // emit 'content-loaded' event -> this could be useful when new content needs to be initialized
      infiniteScroll.element.dispatchEvent(new CustomEvent('content-loaded', {detail: filePath}));
    });
  };

  function getFilePath(infiniteScroll) { // get path of the file to load
    return (Array.isArray(infiniteScroll.options.path)) 
      ? infiniteScroll.options.path[infiniteScroll.index]
      : infiniteScroll.options.path.replace('{n}', infiniteScroll.index+1);
  };

  function getNewContent(path, cb) {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200) cb(this.responseText);
    };
    xhttp.open("GET", path, true);
    xhttp.send();
  };

  function insertNewContent(infiniteScroll, content, moveFocus) {
    var next = false;
    if(infiniteScroll.options.container) {
      var div = document.createElement("div");
      div.innerHTML = content;
      var wrapper = div.querySelector(infiniteScroll.options.container);
      if(wrapper) {
        content = wrapper.innerHTML;
        next = wrapper.getAttribute('data-path');
      }
    }
    var lastItem = false;
    if(moveFocus) lastItem = getLastChild(infiniteScroll);
    if(infiniteScroll.container) {
      infiniteScroll.container.insertAdjacentHTML('beforeend', content);
    } else {
      (infiniteScroll.loader.length > 0) 
        ? infiniteScroll.loader[0].insertAdjacentHTML('beforebegin', content)
        : infiniteScroll.element.insertAdjacentHTML('beforeend', content);
    }
    if(moveFocus && lastItem) elMoveFocus(lastItem);

    return next;
  };

  function getLastChild(infiniteScroll) {
    if(infiniteScroll.container) return infiniteScroll.container.lastElementChild;
    if(infiniteScroll.loader.length > 0) return infiniteScroll.loader[0].previousElementSibling;
    return infiniteScroll.element.lastElementChild;
  };

  function checkNextPageAvailable(infiniteScroll, checkNext) { // check if there's still content to be loaded
    if(Array.isArray(infiniteScroll.options.path)) {
      return infiniteScroll.options.path.length > infiniteScroll.index + 1;
    }
    return checkNext;
  };

  function contentWasLoaded(infiniteScroll, url, checkNext) { // new content has been loaded - reset status 
    if(infiniteScroll.loader.length > 0) infiniteScroll.loader[0].classList.add('is-hidden'); // hide loader
    
    if(infiniteScroll.options.updateHistory && url) { // update browser history
      var pageArray = location.pathname.split('/'),
        actualPage = pageArray[pageArray.length - 1] ;
      if( actualPage != url && history.pushState ) window.history.replaceState({path: url},'',url);
    }
    
    if(!checkNext) { // no need to load additional pages - remove scroll listening and/or click listening
      removeScrollEvents(infiniteScroll);
      if(infiniteScroll.clickEvent) {
        infiniteScroll.loadBtn[0].removeEventListener('click', infiniteScroll.clickEvent);
        infiniteScroll.loadBtn[0].classList.add('is-hidden');
        infiniteScroll.loadBtn[0].classList.remove('sr-only');
      }
    }
    
    if(checkNext && infiniteScroll.options.loadBtn) { // check if we need to switch from scrolling to click -> add/remove proper listener
      if(!infiniteScroll.options.loadBtnDelay) {
        infiniteScroll.loadBtn[0].classList.remove('sr-only');
      } else if(infiniteScroll.index - infiniteScroll.currentPageIndex + 1 >= infiniteScroll.options.loadBtnDelay && infiniteScroll.loadBtn.length > 0) {
        removeScrollEvents(infiniteScroll);
        infiniteScroll.loadBtn[0].classList.remove('sr-only');
      }
    }
    
    if(checkNext && infiniteScroll.loadBtn.length > 0 && infiniteScroll.loadBtn[0].classList.contains('js-infinite-scroll__btn-focus')) { // keyboard accessibility
      infiniteScroll.loadBtn[0].classList.remove('sr-only');
    }

    infiniteScroll.index = infiniteScroll.index + 1;
    infiniteScroll.loading = false;
  };

  function removeScrollEvents(infiniteScroll) {
    if(infiniteScroll.scrollEvent) window.removeEventListener('scroll', infiniteScroll.scrollEvent);
    if(infiniteScroll.observer) infiniteScroll.observer.unobserve(infiniteScroll.observed);
  };

  function needLoad(infiniteScroll) { // intersectionObserverSupported not supported -> check if threshold has been reached
    return infiniteScroll.element.getBoundingClientRect().bottom - window.innerHeight <= infiniteScroll.thresholdPixel;
  };

  function emitCustomEvents(infiniteScroll, eventName, moveFocus) { // applicable when user takes care of loading new content
		var event = new CustomEvent(eventName, {detail: {index: infiniteScroll.index+1, moveFocus: moveFocus}});
		infiniteScroll.element.dispatchEvent(event);
	};

  function elMoveFocus(element) {
    element.focus();
    if (document.activeElement !== element) {
      element.setAttribute('tabindex','-1');
      element.focus();
    }
  };

  var extendProps = function () {
    // Variables
    var extended = {};
    var deep = false;
    var i = 0;
    var length = arguments.length;
    // Check if a deep merge
    if ( Object.prototype.toString.call( arguments[0] ) === '[object Boolean]' ) {
    deep = arguments[0];
    i++;
    }
    // Merge the object into the extended object
    var merge = function (obj) {
    for ( var prop in obj ) {
    if ( Object.prototype.hasOwnProperty.call( obj, prop ) ) {
    // If deep merge and property is an object, merge properties
    if ( deep && Object.prototype.toString.call(obj[prop]) === '[object Object]' ) {
    extended[prop] = extend( true, extended[prop], obj[prop] );
    } else {
    extended[prop] = obj[prop];
    }
    }
    }
    };
    // Loop through each object and conduct a merge
    for ( ; i < length; i++ ) {
    var obj = arguments[i];
    merge(obj);
    }
    return extended;
    };

  InfiniteScroll.defaults = {
    element : '',
    path : false, // path of files to be loaded: set to comma-separated list or string (should include {n} to be replaced by integer index). If not set, user will take care of loading new content
    container: false, // Append new content to this element. Additionally, when loaded a new page, only content of the element will be appended
    threshold: '200px', // distance between viewport and scroll area for loading new content
    updateHistory: false, // push new url to browser history
    loadBtn: false, // use a button to load more content
    loadBtnDelay: false // set to an integer if you want the load more button to be visible only after a number of loads on scroll - loadBtn needs to be 'on'
  };

  window.InfiniteScroll = InfiniteScroll;

  //initialize the InfiniteScroll objects
  var infiniteScroll = document.getElementsByClassName('js-infinite-scroll'),
    intersectionObserverSupported = ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype);

  if( infiniteScroll.length > 0) {
    for( var i = 0; i < infiniteScroll.length; i++) {
      (function(i){
        var path = infiniteScroll[i].getAttribute('data-path') ? infiniteScroll[i].getAttribute('data-path') : false,
        container = infiniteScroll[i].getAttribute('data-container') ? infiniteScroll[i].getAttribute('data-container') : false,
        updateHistory = ( infiniteScroll[i].getAttribute('data-history') && infiniteScroll[i].getAttribute('data-history') == 'on') ? true : false,
        loadBtn = ( infiniteScroll[i].getAttribute('data-load-btn') && infiniteScroll[i].getAttribute('data-load-btn') == 'on') ? true : false,
        loadBtnDelay = infiniteScroll[i].getAttribute('data-load-btn-delay') ? infiniteScroll[i].getAttribute('data-load-btn-delay') : false,
        threshold = infiniteScroll[i].getAttribute('data-threshold') ? infiniteScroll[i].getAttribute('data-threshold') : '200px';
        new InfiniteScroll({element: infiniteScroll[i], path : path, container : container, updateHistory: updateHistory, loadBtn: loadBtn, loadBtnDelay: loadBtnDelay, threshold: threshold});
      })(i);
    }
  };
}());