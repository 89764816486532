// File#: _2_gallery-v2
// Usage: codyhouse.co/license
(function() {
  var ParallaxCard = function(element) {
    this.element = element;
    this.bgCard = this.element.getElementsByClassName('js-gallery-v2__icon-bg');
    this.deltaTranslate = 10;
    if(this.bgCard.length < 1) return;
    initParallaxCard(this);
  };

  function initParallaxCard(element) {
    // init the CursorFx object
    new CursorFx({
      target: element.element,
      objects: [{element: element.bgCard[0], effect: 'parallax', delta: element.deltaTranslate}]
    });
  };

  // init ParallaxCard object
  var parallaxcard = document.getElementsByClassName('js-gallery-v2__img-wrapper');
  if( parallaxcard.length > 0 ) {
    for( var i = 0; i < parallaxcard.length; i++) {
      new ParallaxCard(parallaxcard[i]);
    }
  }
}());