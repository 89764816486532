(function ($, window, undefined) {
	'use strict';
	$(document).ready(function () {
		
		//console.log('forms2.js');
		
		/*----------------------------------------------------------------------------------------------------
		Phone Input :::: https://github.com/jackocnr/intl-tel-input#utilities-script
		----------------------------------------------------------------------------------------------------*/
		$('.input-phone').each(function() {
			$(this).spPhone();
		}); 
			
		/*----------------------------------------------------------------------------------------------------
		Address Form Group
		----------------------------------------------------------------------------------------------------*/
			$('.formgroup--address').each(function() {
				$(this).spAddressAutocomplete();
			});

		/*----------------------------------------------------------------------------------------------------
		Schedule Form Group
		----------------------------------------------------------------------------------------------------*/
			$('.formgroup--schedule').each(function() {
				$(this).spSchedule();
			});


		/*----------------------------------------------------------------------------------------------------
		Order Form
		----------------------------------------------------------------------------------------------------*/
			$('.order-form').each(function() {
				
			});


		/*----------------------------------------------------------------------------------------------------
		Order Help
		----------------------------------------------------------------------------------------------------*/
			$('.help-form').each(function() {
				//console.log('help-form');
				var _help 			= $(this), 
					select 			= _help.find('.js-select-auto__select'),
					orderID 		= _help.find('.order-num'),
					orderPrice 		= _help.find('.order-price'),
					orderAddress 	= _help.find('address');

					select.on('change', function() {
						//console.log('lslslls');
						//console.log($(this).val());
					});
			});

		
			
	});
	//READY
})(jQuery, undefined);
