(function ($) {
	// EXTEND
	
	console.log('forms-address.js');
	
	
	/*-----------------------------------------------------
	Address Full Field
	------------------------------------------------------*/
	$.fn.extend({
		spAddressAutocomplete: function(){
			var $this = $(this),
				$addressFinder 	= $this.find(".sp__address--finder"),
				$address 		= $this.find(".sp__control--address"),
				$street 		= $this.find(".sp__control--street"),
				$city 			= $this.find(".sp__control--locality"),
				$sublocality 	= $this.find(".sp__control--sublocality"),
				$state 			= $this.find(".sp__control--state"),
				$zip 			= $this.find(".sp__control--zip"),
				$num_exterior 	= $this.find(".sp__control--number"),
				$num_interior  	= $this.find(".sp__control--interior"),
				$customAddress 	= $this.find(".sp__control--customaddress"),
				componentForm = {
					route: {field:'long_name',obj:$street},
					street_number: {field:'long_name',obj:$num_exterior},
					locality: {field:'short_name',obj:$city},
					neighborhood: {field:'long_name',obj:$sublocality},
					sublocality_level_1: {field:'short_name',obj:$sublocality},
					administrative_area_level_1: {field:'short_name',obj:$state},
					administrative_area_level_2: {field:'short_name',obj:$state},
					postal_code: {field:'short_name',obj:$zip}
				};

				var _cartAddress = $('.cart-address'); //on Summary

				var _summaryAddresStreet 		= $('.summary_addr_street'),
					_summaryAddressLocality 	= $('.summary_addr_locality'),
					_summaryAddressSubLocality 	= $('.summary_addr_sublocality'),
					_summaryAddressState	 	= $('.summary_addr_state'),
					_summaryAddressZip 			= $('.summary_addr_zip');


			function init(){
				if(typeof google.maps.places.Autocomplete != 'undefined'){

					//autocomplete = new google.maps.places.Autocomplete($address.get(0),{types: ['address'],componentRestrictions: {country: "mx"}});
					autocomplete = new google.maps.places.Autocomplete($address.get(0),{types: ['address']});
					autocomplete.addListener('place_changed', function(){
						var place = this.getPlace();
						console.log('place_changed',place);

						
						_cartAddress.css("display","flex");

						for( var aux in componentForm ){
							componentForm[aux].obj.val('');
							componentForm[aux].obj.prop('readonly',false);
							componentForm[aux].obj.prop('disabled',false);
						}

						for (var i = 0; i < place.address_components.length; i++) {
							var addressType = place.address_components[i].types[0];
							if (componentForm[addressType]) {
								componentForm[addressType].obj.val(place.address_components[i][componentForm[addressType].field]);
								componentForm[addressType].obj.prop('readonly', true);
								componentForm[addressType].obj.prop('disabled', true);
							}
						}
						
						if (componentForm['street_number'].obj.val() !== '') {
							var street = componentForm['route'].obj.val();
							street += ' #' + componentForm['street_number'].obj.val();
							$street.val(street);
						}

						$num_interior.prop('readonly',false);
						$num_interior.prop('disabled',false);
						

						$this.trigger('place_changed',[place]);

						
						streetHTML = $street.val();
						if ($num_interior.val() !== '') {
							streetHTML += ', ' + $num_interior.val();
						}

						_summaryAddresStreet.text(streetHTML);

						if ($city.val() !== '') {
							_summaryAddressLocality.text( $city.val());
						}

						if ($sublocality.val() !== '') {
							_summaryAddressSubLocality.text(', ' + $sublocality.val() + ',');
						}

						if ($state.val() !== '') {
							_summaryAddressState.text($state.val());
						}

						if ($zip.val() !== '') {
							_summaryAddressZip.text(', ' + $zip.val());
						}

					});
					$this.data('spAddressAutocomplete',autocomplete);
				} else {
					_cartAddress.hide();
					setTimeout(init,100);	
				}

				
			}

			// Agregar un controlador de eventos al hacer clic en el checkbox
			$customAddress.on('click', function() {
				if ($(this).is(':checked')) {

					// Habilitar los campos de dirección
				    $street.focus();
					$street.prop('readonly', false);
					$street.prop('disabled', false);
					$city.prop('readonly', false);
					$city.prop('disabled', false);
					$sublocality.prop('readonly', false);
					$sublocality.prop('disabled', false);
					$state.prop('readonly', false);
					$state.prop('disabled', false);
					$zip.prop('readonly', false);
					$zip.prop('disabled', false);
					$num_exterior.prop('readonly',false);
					$num_exterior.prop('disabled',false);
					$num_interior.prop('readonly',false);
					$num_interior.prop('disabled',false);

				    // Ocultar el botón "Buscar dirección"
				    $addressFinder.hide();

				    // Cambiar el texto del label del checkbox
				    $(this).next().text('Return to search address');

				  } else {
				    
				    // Deshabilitar los campos de dirección
				    $address.focus();
					$street.prop('readonly', true);
					$city.prop('readonly', true);
					$sublocality.prop('readonly', true);
					$state.prop('readonly', true);
					$zip.prop('readonly', true);
					$num_exterior.prop('readonly',true);
					$num_interior.prop('readonly',false);

				    // Mostrar el botón "Buscar dirección"
				    $addressFinder.show();

				    // Cambiar el texto del label del checkbox
				    $(this).next().text('My address not appear');
				  }
			});

			$street.add($city).add($sublocality).add($state).add($zip).add($num_exterior).add($num_interior).on('input', function() {
  				// Actualizar el contenido de los elementos span correspondientes

  				if ($street.val() !== '') {
  					streetHTML = $street.val();
					if ($num_interior.val() !== '') {
						streetHTML += ', ' + $num_interior.val();
					}
					_summaryAddresStreet.text(streetHTML + ',');
				} else {
					_summaryAddresStreet.text('');
				}


				if ($city.val() !== '') {
					_summaryAddressLocality.text($city.val());
				} else {
					_summaryAddressLocality.text('');
				}
				if ($sublocality.val() !== '') {
					_summaryAddressSubLocality.text(', ' + $sublocality.val() + ',');
				} else {
					_summaryAddressSubLocality.text('');
				}
				if ($state.val() !== '') {
					_summaryAddressState.text($state.val());
				} else {
					_summaryAddressState.text('');
				}
				if ($zip.val() !== '') {
					_summaryAddressZip.text(', ' + $zip.val());
				} else {
					_summaryAddressZip.text('');
				}
				
				if ($street.val() === '' && $city.val() === '' && $sublocality.val() === '' && $state.val() === '' && $zip.val() === '' && $num_exterior.val() === '' && $num_interior.val() === '') {
					_cartAddress.hide();
				} else {
					_cartAddress.css("display","flex");
				}

			}).on('blur', function() {
				// Verificar si todos los campos están vacíos y ocultar _cartAddress si es el caso
				if ($street.val() === '' && $city.val() === '' && $sublocality.val() === '' && $state.val() === '' && $zip.val() === '' && $num_exterior.val() === '' && $num_interior.val() === '') {
					_cartAddress.hide();
				}
			});

			if($address.length){

				setTimeout(init,100);

			}
		}
	});


	//END EXTEND
})(jQuery);