// File#: _1_expandable-side-navigation
// Usage: codyhouse.co/license
(function() {
  var Exsidenav = function(element) {
		this.element = element;
    this.controls = this.element.getElementsByClassName('js-exsidenav__control');
    this.index = 0;
		initExsidenav(this);
	};

  function initExsidenav(element) {
    // set aria attributes
    initAria(element);
    // detect click on control buttons
    element.element.addEventListener('click', function(event){
      var control = event.target.closest('.js-exsidenav__control');
      if(control) toggleNav(control);
    });
  };

  function initAria(element) {
    // set aria attributes -> aria-controls and aria-expanded
    var randomNum = getRandomInt(0, 1000);
    for(var i = 0; i < element.controls.length; i++) {
      var newId = 'exsidenav-'+randomNum+'-'+element.index,
        id = element.controls[i].nextElementSibling.getAttribute('id');
      if(!id) {
        id = newId;
        element.controls[i].nextElementSibling.setAttribute('id', newId);
      }
      element.index = element.index + 1;
      element.controls[i].setAttribute('aria-controls', id);
      if(!element.controls[i].getAttribute('aria-expanded')) element.controls[i].setAttribute('aria-expanded', 'false');
    }
  };

  function toggleNav(control) {
    // open/close sub list
    var bool = (control.getAttribute('aria-expanded') === 'true'),
      ariaValue = bool ? 'false' : 'true';
    control.setAttribute('aria-expanded', ariaValue);
  };

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); 
  };

  window.Exsidenav = Exsidenav;
	
	//initialize the Exsidenav objects
	var exsidenav = document.getElementsByClassName('js-exsidenav');
	if( exsidenav.length > 0 ) {
		for( var i = 0; i < exsidenav.length; i++) {
			(function(i){new Exsidenav(exsidenav[i]);})(i);
		}
	}
}());