if(!Util) function Util () {};

Util.hasClass = function(el, className) {
  return el.classList.contains(className);
};

Util.addClass = function(el, className) {
  var classList = className.split(' ');
  el.classList.add(classList[0]);
  if (classList.length > 1) Util.addClass(el, classList.slice(1).join(' '));
};

Util.removeClass = function(el, className) {
  var classList = className.split(' ');
  el.classList.remove(classList[0]);
  if (classList.length > 1) Util.removeClass(el, classList.slice(1).join(' '));
};

Util.toggleClass = function(el, className, bool) {
  if(bool) Util.addClass(el, className);
  else Util.removeClass(el, className);
};

Util.extend = function() {
  var extended = {};
  var deep = false;
  var i = 0;
  var length = arguments.length;

  if ( Object.prototype.toString.call( arguments[0] ) === '[object Boolean]' ) {
    deep = arguments[0];
    i++;
  }

  var merge = function (obj) {
    for ( var prop in obj ) {
      if ( Object.prototype.hasOwnProperty.call( obj, prop ) ) {
        if ( deep && Object.prototype.toString.call(obj[prop]) === '[object Object]' ) {
          extended[prop] = extend( true, extended[prop], obj[prop] );
        } else {
          extended[prop] = obj[prop];
        }
      }
    }
  };

  for ( ; i < length; i++ ) {
    var obj = arguments[i];
    merge(obj);
  }

  return extended;
};

// File#: _2_password-strength
// Usage: codyhouse.co/license
(function() {
  var PasswordStrength = function(opts) {
    this.options = Util.extend(PasswordStrength.defaults , opts); // used to store custom filter/sort functions
    this.element = this.options.element;
    this.input = this.element.getElementsByClassName('js-password-strength__input');
    this.reqs = this.element.getElementsByClassName('js-password-strength__req');
    this.strengthSection = this.element.getElementsByClassName('js-password-strength__meter-wrapper');
    this.strengthValue = this.element.getElementsByClassName('js-password-strength__value');
    this.strengthMeter = this.element.getElementsByClassName('js-password-strength__meter');
    this.passwordInteracted = false;
    this.reqMetClass = 'password-strength__req--met';
    this.reqNoMetClass = 'password-strength__req--no-met';
    shouldCheckStrength(this); // check if password strength should be checked
    getStrengthLabels(this); // labels for password strength
    initPasswordStrength(this);
  };

  function shouldCheckStrength(password) {
    password.checkStrength = true;
    var checkStrength = password.element.getAttribute('data-check-strength');
    if(checkStrength && checkStrength == 'off') password.checkStrength = false;
  };

  function getStrengthLabels(password) {
    if(!password.checkStrength) password.strengthLabels = false;
    password.strengthLabels = ['Bad', 'Weak', 'Good', 'Strong'];
    var dataLabel = password.element.getAttribute('data-strength-labels');
    if(dataLabel) {
      var labels = dataLabel.split(',');
      if(labels.length < 4) return;
      password.strengthLabels = labels.map(function(element){return element.trim()});
    }
  };

  function initPasswordStrength(password) {
    if(password.input.length == 0) return;
    toggleCheckStrength(password); // hide/show password strenght section
    checkStrength(password);
    checkConditions(password);
    initInput(password);
  };

  function initInput(password) {
    password.input[0].addEventListener('input', function(event) { // password changed
      toggleCheckStrength(password); // hide/show password strenght section
      checkStrength(password);
      checkConditions(password);
    });

    password.input[0].addEventListener('blur', function cb(event) {
      password.input[0].removeEventListener('blur', cb);
      password.passwordInteracted = true;
      // show error for requirement not met
      for(var i = 0; i < password.reqs.length; i++) {
        if(!Util.hasClass(password.reqs[i], password.reqMetClass)) Util.addClass(password.reqs[i], password.reqNoMetClass);
      }
    });
  };

  function toggleCheckStrength(password) {
    if(password.strengthSection.length == 0) return;
    Util.toggleClass(password.strengthSection[0], 'is-hidden', (password.input[0].value.length == 0));
  };

  function checkStrength(password) {
    if(!password.checkStrength || !zxcvbn) return;
    var response = zxcvbn(password.input[0].value);
    if(password.strengthValue.length > 0) { // update strength label
      if(response.score >= 1) password.strengthValue[0].textContent = password.strengthLabels[response.score - 1];
      else password.strengthValue[0].textContent = password.strengthLabels[0];
    }

    if(password.strengthMeter.length > 0) { // update strength meter
      var score = response.score;
      if(score == 0 && password.input[0].value.length > 0) score = 1;
      password.strengthMeter[0].firstElementChild.style.width = score/0.04+'%';
      removeStrengthClasses(password);
      if(response.score >= 1) Util.addClass(password.strengthMeter[0], 'password-strength__meter--fill-'+response.score);
      else Util.addClass(password.strengthMeter[0], 'password-strength__meter--fill-1');
    }
  };

  function checkConditions(password) {
    // uppercase, lowercase, special characters, length, number + custom
    for(var i = 0; i < password.reqs.length; i++) {
      var req = password.reqs[i].getAttribute('data-password-req');
      var result = false;
      if(password.options[req]) {
        result = password.options[req](password.input[0].value);
      } else {
        result = checkSingleCondition(password.input[0].value, req);
      }

      Util.toggleClass(password.reqs[i], password.reqMetClass, result);
      if(password.passwordInteracted) Util.toggleClass(password.reqs[i], password.reqNoMetClass, !result);
    }
  };

  function checkSingleCondition(value, req) {
    var result;
    switch (true) {
      case (req.trim() == 'uppercase'):
        result = (value.toLowerCase() != value);
        break;
      case (req.trim() == 'lowercase'):
        result = (value.toUpperCase() != value);
        break;
      case (req.trim() == 'number'):
        result = /\d/.test(value);
        break;
      case (req.indexOf('length:') == 0):
        var reqArray = req.split(':');
        result = (value.length >= parseInt(reqArray[1]));
        if(reqArray.length > 2 && result) result = (value.length <= parseInt(reqArray[2]));
        break;
      case (req.trim() == 'special'):
        result = /[!@#$%^&*=~`'"|/\?\_\-\,\;\.\:\(\)\{\}\[\]\+\>\<\\]/.test(value);
        break;
      case (req.trim() == 'letter'):
        result = /[a-zA-Z]/.test(value);
        break;
      default:
        result = false;
        break;
    }
    return result;
  };

  function removeStrengthClasses(password) {
    var classes = password.strengthMeter[0].className.split(" ").filter(function(c) {
      return c.lastIndexOf('password-strength__meter--fill-', 0) !== 0;
    });
    password.strengthMeter[0].className = classes.join(" ").trim();
  };

  PasswordStrength.defaults = {
    element : false,
  };

  window.PasswordStrength = PasswordStrength;

  //initialize the PasswordStrength objects
	var passwordStrength = document.getElementsByClassName('js-password-strength');
	if( passwordStrength.length > 0 ) {
		for( var i = 0; i < passwordStrength.length; i++) {
			(function(i){new PasswordStrength({element: passwordStrength[i]});})(i);
		}
  };
}());