if(!Util) function Util () {};

Util.hasClass = function(el, className) {
  return el.classList.contains(className);
};

Util.addClass = function(el, className) {
  var classList = className.split(' ');
  el.classList.add(classList[0]);
  if (classList.length > 1) Util.addClass(el, classList.slice(1).join(' '));
};

Util.removeClass = function(el, className) {
  var classList = className.split(' ');
  el.classList.remove(classList[0]);
  if (classList.length > 1) Util.removeClass(el, classList.slice(1).join(' '));
};

Util.toggleClass = function(el, className, bool) {
  if(bool) Util.addClass(el, className);
  else Util.removeClass(el, className);
};

// File#: _1_password
// Usage: codyhouse.co/license
(function() {
	var Password = function(element) {
		this.element = element;
		this.password = this.element.getElementsByClassName('js-password__input')[0];
		this.visibilityBtn = this.element.getElementsByClassName('js-password__btn')[0];
		this.visibilityClass = 'password--text-is-visible';
		this.initPassword();
	};

	Password.prototype.initPassword = function() {
		var self = this;
		//listen to the click on the password btn
		this.visibilityBtn.addEventListener('click', function(event) {
			//if password is in focus -> do nothing if user presses Enter
			if(document.activeElement === self.password) return;
			event.preventDefault();
			self.togglePasswordVisibility();
		});
	};

	Password.prototype.togglePasswordVisibility = function() {
		var makeVisible = !Util.hasClass(this.element, this.visibilityClass);
		//change element class
		Util.toggleClass(this.element, this.visibilityClass, makeVisible);
		//change input type
		(makeVisible) ? this.password.setAttribute('type', 'text') : this.password.setAttribute('type', 'password');
	};
	
	//initialize the Password objects
	var passwords = document.getElementsByClassName('js-password');
	if( passwords.length > 0 ) {
		for( var i = 0; i < passwords.length; i++) {
			(function(i){new Password(passwords[i]);})(i);
		}
	};
}());