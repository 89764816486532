// File#: _3_looping-slideshow-v2
// Usage: codyhouse.co/license
(function() {
  var LoopSlideshow2 = function(element) {
    this.element = element;
    this.slides = this.element.getElementsByClassName('js-slideshow-pm__item');
    this.videos = getSlideshowVideo(this);
    this.loader = this.element.getElementsByClassName('js-loop-slideshow-v2__loader');
    this.slideshoObj = false;
    this.slideshoDotNav = false;
    this.timeoutId = false;
    this.defaultLoop = this.element.getAttribute('data-loop') ? this.element.getAttribute('data-loop') : 5000;
    this.fillingCSS = '--loop-slideshow-filling-v2';
    this.animating = false;
    this.observer = false;
    initLoopSlideshow2(this);
    initLoopSlideshow2Events(this);
  };

  function getSlideshowVideo(el) {
    var videos = [];
    for(var i = 0; i < el.slides.length; i++) {
      videos.push(el.slides[i].querySelector('video'));
    }
    return videos;
  };

  function initLoopSlideshow2(el) {
    // init SlideshowPrew object
    var prewNav = (el.element.getAttribute('data-pm-nav') && el.element.getAttribute('data-pm-nav') == 'on' ) ? true : false, 
			swipe = (el.element.getAttribute('data-swipe') && el.element.getAttribute('data-swipe') == 'on') ? true : false;
    el.slideshoObj = new SlideshowPrew({element: el.element, navClass: 'loop-slideshow-v2__navigation', navItemClass: 'loop-slideshow-v2__nav-item', navBtnClass: 'loop-slideshow-v2__nav-btn', prewNav: prewNav, swipe: swipe});

    // store nav dot items
    el.slideshoDotNav = el.element.getElementsByClassName('js-slideshow-pm__nav-item');
    
    // use intersection observer to trigger start/stop of loop and video play
    el.observer = new IntersectionObserver(loopSlideshowObserve.bind(el));
    el.observer.observe(el.element);
  };

  function initLoopSlideshow2Events(el) {
    el.element.addEventListener('newSlide', function(event) { 
      // new slide has been selected
      clearTimeout(el.timeoutId);
      window.cancelAnimationFrame(el.animating);
      playVideo(el, event.detail);
    });
  };

  function loopSlideshowObserve(entries) {
    clearTimeout(this.timeoutId);
    window.cancelAnimationFrame(this.animating);
    this.timeoutId = false;
    this.animating = false;
    entries[0].isIntersecting ? playSlideshow(this) : pauseSlideshow(this);
  };

  function playVideo(el, index) {
    for(var i = 0; i < el.videos.length; i++) {
      if(i == index) {
        var delay = el.defaultLoop;
        if(el.videos[i]) {
          el.videos[i].pause();
          el.videos[i].currentTime = 0;
          el.videos[i].play();
          delay = 1000 * (el.videos[i].duration % 60);
        }
        startLoaderAnim(el, delay, i);
      } else if(el.videos[i]) {
        el.videos[i].pause();
      }
    }
  };

  function playSlideshow(el) {
    playVideo(el, el.slideshoObj.selectedSlide);
  };

  function pauseSlideshow(el) {
    var video = el.videos[el.slideshoObj.selectedSlide]
    if(video) video.pause();
  };

  function startLoaderAnim(el, duration, index) {
    window.cancelAnimationFrame(el.animating);
    if(isNaN(duration)) {
      return setTimeout(function(){
        startLoaderAnim(el, 1000 * (el.videos[index].duration % 60), index);
      }, 100);
    }

    // start loader animation
    resetDotNav(el);
    animateFilling(el, el.loader[0], index, duration);

    // timeout for next slide
    el.timeoutId = setTimeout(function(){
      nextSlide(el);
    }, duration);
  };

  function nextSlide(el) {
    if(el.slideshoObj.selectedSlide == el.slides.length - 1) {
      el.slideshoObj.showItem(0);
    } else {
      el.slideshoObj.showNext();
    }
  };

  function resetDotNav(el) {
    // reset dot filling to zero
    for(var i = 0; i < el.slideshoDotNav.length; i++) setFilling(el.slideshoDotNav[i], el.fillingCSS, 0);
    window.cancelAnimationFrame(el.animating);
    el.currentTime = false;
  };

  function animateFilling(el, loader, index, duration) {
    var strokeOffset = 144.44; // stroke-dashoffset of js-loop-slideshow-v2__loader 
    el.animating = window.requestAnimationFrame(function(timestamp){
      if(!el.currentTime) el.currentTime = timestamp;
      var progress = timestamp - el.currentTime;
      if(progress > duration) progress = duration;
      setFilling(loader, el.fillingCSS, ((1 - progress/duration)*strokeOffset).toFixed(3)); // animate arrow
      if(el.slideshoDotNav.length > index) setFilling(el.slideshoDotNav[index], el.fillingCSS, (progress/duration).toFixed(3)); // animte nav dot filling on small devices
      
      if(progress < duration) {
        animateFilling(el, loader, index, duration);
      } else {
        // animation is over
        el.animating = false;
        el.currentTime = false;
      }
    });
  };

  function setFilling(element, property, value) {
    element.style.setProperty(property, value);
  };

  //initialize the LoopSlideshow2 objects
	var slideshow = document.getElementsByClassName('js-loop-slideshow-v2');
  if( slideshow.length > 0 ) {
    for( var i = 0; i < slideshow.length; i++) {
      (function(i){
        new LoopSlideshow2(slideshow[i]);
      })(i);
    }
  }
}());