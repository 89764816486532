(function ($) {
	// EXTEND
	
	//console.log('forms-schedule.js');
	
	/*-----------------------------------------------------
	Schedule
	------------------------------------------------------*/
	$.fn.extend({

		

		spSchedule: function(){
			var $this 			= $(this),
				$dateInput 		= $this.find(".sp__control--date"),
				$timeInput 		= $this.find(".sp__control--time");

				if ($.fn.pickadate) {
					$dateInput.pickadate({
						format: 'ddd, dd mmm, yyyy',
						min: 1,
						disable: [1, 7]
					});
					$timeInput.pickatime({
						format: 'h:i A',
						formatLabel: 'HH:i !hrs.',
						interval: 120,
						min: [9,00],
  						max: [17,00]
					});
				}
			
		}
	});

	//END EXTEND
})(jQuery);