// File#: _1_reading-progressbar
// Usage: codyhouse.co/license
(function() {
  var readingIndicator = document.getElementsByClassName('js-reading-progressbar')[0],
		readingIndicatorContent = document.getElementsByClassName('js-reading-content')[0];
  
  if( readingIndicator && readingIndicatorContent) {
    var progressInfo = [],
      progressEvent = false,
      progressFallback = readingIndicator.getElementsByClassName('js-reading-progressbar__fallback')[0],
      progressIsSupported = 'value' in readingIndicator;

    var boundingClientRect = readingIndicatorContent.getBoundingClientRect();

    progressInfo['height'] = readingIndicatorContent.offsetHeight;
    progressInfo['top'] = boundingClientRect.top;
    progressInfo['bottom'] = boundingClientRect.bottom;
    progressInfo['window'] = window.innerHeight;
    progressInfo['class'] = 'reading-progressbar--is-active';
    progressInfo['hideClass'] = 'reading-progressbar--is-out';
    
    //init indicator
    setProgressIndicator();
    // wait for font to be loaded - reset progress bar
    if(document.fonts) {
      document.fonts.ready.then(function() {
        triggerReset();
      });
    }
    // listen to window resize - update progress
    window.addEventListener('resize', function(event){
      triggerReset();
    });

    //listen to the window scroll event - update progress
    window.addEventListener('scroll', function(event){
      if(progressEvent) return;
      progressEvent = true;
      (!window.requestAnimationFrame) ? setTimeout(function(){setProgressIndicator();}, 250) : window.requestAnimationFrame(setProgressIndicator);
    });
    
    function setProgressIndicator() {
      var boundingClientRect = readingIndicatorContent.getBoundingClientRect();
      progressInfo['top'] = boundingClientRect.top;
      progressInfo['bottom'] = boundingClientRect.bottom;

      if(progressInfo['height'] <= progressInfo['window']) {
        // short content - hide progress indicator
        readingIndicator.classList.remove(progressInfo['class']);
        progressEvent = false;
        return;
      }
      // get new progress and update element
      readingIndicator.classList.add(progressInfo['class']);
      var value = (progressInfo['top'] >= 0) ? 0 : 100*(0 - progressInfo['top'])/(progressInfo['height'] - progressInfo['window']);
      readingIndicator.setAttribute('value', value);
      if(!progressIsSupported && progressFallback) progressFallback.style.width = value+'%';
      // hide progress bar when target is outside the viewport
      readingIndicator.classList.toggle(progressInfo['hideClass'], progressInfo['bottom'] <= 0);
      progressEvent = false;
    };

    function triggerReset() {
      if(progressEvent) return;
      progressEvent = true;
      (!window.requestAnimationFrame) ? setTimeout(function(){resetProgressIndicator();}, 250) : window.requestAnimationFrame(resetProgressIndicator);
    };

    function resetProgressIndicator() {
      progressInfo['height'] = readingIndicatorContent.offsetHeight;
      progressInfo['window'] = window.innerHeight;
      setProgressIndicator();
    };
  }
}());