/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

jQuery.event.special.touchstart = {
	setup: function( _, ns, handle ) {
		this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
	}
};
jQuery.event.special.touchmove = {
	setup: function( _, ns, handle ) {
		this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
	}
};
jQuery.event.special.wheel = {
	setup: function( _, ns, handle ){
		this.addEventListener("wheel", handle, { passive: true });
	}
};
jQuery.event.special.mousewheel = {
	setup: function( _, ns, handle ){
		this.addEventListener("mousewheel", handle, { passive: true });
	}
};

/*----------------------------------------------------------------------------------------------------
MODALS
----------------------------------------------------------------------------------------------------*/
function openModal(element) {
	var event = new CustomEvent('openModal');
	element.dispatchEvent(event);
};
function closeModal(element) {
	var event = new CustomEvent('closeModal');
	element.dispatchEvent(event);
};

var modal = document.getElementsByClassName('js-modal')[0];
modal.addEventListener('modalIsOpen', function(event){
	//console.log('modal is open');
});


/*----------------------------------------------------------------------------------------------------
TEST
----------------------------------------------------------------------------------------------------*/
function calculateAll($field = 'input[data-service]'){
	count = 0;
	$($field).each(function(index, checkbox){
	if(checkbox.checked)
		count += parseInt(checkbox.value)
	});
	return count;  
}

function countServices($field = 'input[data-service]'){
	var i = 0,
		total = 0;

	$($field).each(function(index, checkbox){
	if(checkbox.checked)
		total += parseInt(i+1)
	});
	return total;  
}

function labelAll($field = 'input[data-service]', $field_data = ''){
	var array = [];
	switch($field_data) {
		case 'title':
			$data = 'title';
			break;

		case 'subtitle':
			$data = 'subtitle';
			break;

		case 'price':
			$data = 'price';
			break;

		default:
			$data = '';
	}
	$($field).each(function(index, checkbox){
		if(checkbox.checked)
			 array.push($(this).data('title'));
		 
	});

	return array;  
}




(function($) {


	var History = window.History;

	var options = {
		mediaSelector  : '.mobx',
		attraction     : {
			slider : 0.055,
			slide  : 0.018,
			thumbs : 0.016
		},
		scrollToZoom   : true,
		shareButtons   : ['facebook', 'googleplus', 'twitter', 'pinterest', 'linkedin'],
		thumbnailSizes : {
			1920 : {
				width  : 110,
				height : 80,
				gutter : 10
			},
			1280 : {
				width  : 90,
				height : 65,
				gutter : 10
			},
			480 : {
				width  : 60,
				height : 44,
				gutter : 5
			}
		}
	};

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {

				console.log('Test 22');
				/*----------------------------------------------------------------------------------------------------
				Login
				----------------------------------------------------------------------------------------------------*/
					var cookieRealtyflowCheckUser = 'realtyflow_chck_user';
					$('.realtyflow__login').each(function() {

						var _this 					= $(this),
							backForm 				= _this.find('.backform'),
							_panelUser				= _this.find('#panel-user'),
							_panelLogin 			= _this.find('#panel-login'),
							_panelRecovery 			= _this.find('#panel-recovery'),
							checkUserForm 			= _this.find('form[name="check_user"]'),
							checkUserResponseTop 	= checkUserForm.find('.form-response');

						var	loginUserForm 			= _this.find('form[name="log_user"]'),
							loginUserNonce 			= loginUserForm.find('input[name="_wpnonce"]').val(),
							loginUserMailInput 		= loginUserForm.find('input[name="username"]'),
							loginUserPwd 			= loginUserForm.find('form[name="password"]'),
							loginUserResponseHeader = loginUserForm.find('.form-response-header'),
							loginUserResponse 		= loginUserForm.find('.form-response'),
							_loginEmail 			= loginUserForm.find('.user_exists_email'),
							_loginLetter 			= loginUserForm.find('.user_exists_letter'),
							_loginHdrStrong 		= loginUserForm.find('.fname');


							
							$('input[name="user_email"]').focus();
							console.log('FOCUS');

							//== CHECK USER FORM
							checkUserForm.on('submit', function(e) {
								e.preventDefault();

								var checkUserMailInput 	= checkUserForm.find('input[name="user_email"]'),
									checkUserNonce 		= checkUserForm.find('input[name="_wpnonce_usr"]').val(),
									checkUserMailVal 	= checkUserMailInput.val(),
									checkUserSubmit 	= checkUserForm.find('button[name="wp-submit"]'),
									spinner 			= checkUserForm.find('.spinner');

								spinner.show();

								
								$.ajax({
									type: 'POST',
									url: vars.ajax_uri,
									data: {
										action: 'spiga_custom_check_user',
										user_email: checkUserMailVal,
										security: checkUserNonce
									},
									beforeSend: function() {

										checkUserMailInput.prop('disabled', true);
										checkUserSubmit.prop('disabled', true);

									},
									success: function(response) {
										

										spinner.hide();

										if (response.success) {

											//== COOKIE
											if (typeof Cookies !== "undefined") { 
												var cookieValue = {
													name: response.data.first_name,
													email: response.data.email,
													letter: response.data.first_letter
												};
												
												Cookies.set(cookieRealtyflowCheckUser, JSON.stringify(cookieValue) , { expires: 20, path: '/' });

											}

									
											_panelUser.hide();
											_panelLogin.fadeIn();
											$('input[name="password"]').focus();
											loginUserResponseHeader.html(response.data.message);
											loginUserMailInput.val(response.data.email);

											_loginEmail.html(response.data.email);
											_loginLetter.html(response.data.first_letter);
											

										} else {

											checkUserResponseTop.html(response.data.message);
											checkUserMailInput.prop('disabled', false);
											checkUserSubmit.prop('disabled', false);

										}
									},
									error: function(xhr, textStatus, errorThrown) {
										checkUserMailInput.prop('disabled', false);
										checkUserSubmit.prop('disabled', false);
										console.log(xhr.responseText);
									}
								});
							});

							//== RESET FORM
							backForm.on('click', function() {
								console.log('BACK FORM CLICKED 2');
								_panelUser.fadeIn();
								_panelLogin.hide();
								checkUserForm.find('input[name="user_email"]').prop('disabled', false);
								checkUserForm.find('button[name="wp-submit"]').prop('disabled', false);
								checkUserForm.find('input[name="user_email"]').val('');
								$('input[name="user_email"]').focus();
								Cookies.remove(cookieRealtyflowCheckUser, { path: '/' });
							});

							//== LOGIN SUBMIT
							loginUserForm.on('submit', function(e) {
								e.preventDefault();
								

								//== get cookie values
								if (typeof Cookies !== "undefined") { 
									var cookieValue = Cookies.get(cookieRealtyflowCheckUser);
									if (cookieValue !== undefined) {
										console.log(cookieValue);
										_loginEmail.html(cookieValue.email);
										_loginLetter.html(cookieValue.letter);
										
									}
								}

								//== login functions
								var loginUsernameInput 	= loginUserForm.find('input[name="username"]'),
									loginPasswordInput 	= loginUserForm.find('input[name="password"]'),
									loginUsernameVal 	= loginUsernameInput.val(),
									loginPasswordVal 	= loginPasswordInput.val(),
									checkLoginNonce 	= loginUserForm.find('input[name="_wpnonce"]').val(),
									checkLoginSubmit 	= loginUserForm.find('button[name="wp-submit"]'),
									spinner 			= loginUserForm.find('.spinner');

								spinner.show();

								// Enviamos los datos del formulario por Ajax
								$.ajax({
									type: 'POST',
									url: vars.ajax_uri,
									data: {
										action: 'spiga_custom_check_login',
										username: loginUsernameVal,
										password: loginPasswordVal,
										rememberme: true,
										security: checkLoginNonce
									},
									beforeSend: function(){
										loginUserResponse.hide();
										loginPasswordInput.prop('disabled', true);
										checkLoginSubmit.prop('disabled', true);

									},
									success: function(response) {
										
										spinner.hide();
										loginUserResponse.show();

										if (response.success) {

											loginUserResponse.html(response.data.message);
											window.location.href = response.data.redirect;

										} else {

											loginUserResponse.html(response.data.message);
											loginPasswordInput.prop('disabled', false);
											checkLoginSubmit.prop('disabled', false);

										}
									},
									error: function(xhr, status, error) {

										
									},
									complete: function() {
										loginPasswordInput.prop('disabled', false);
										checkLoginSubmit.prop('disabled', false);
									}
								});	
							});
							

							//== COOKIE FUNCTIONS
							if (typeof Cookies !== "undefined") { 
						
								var cookieValue = Cookies.get(cookieRealtyflowCheckUser);
								
								if (cookieValue !== undefined) {
									

									_panelUser.hide();
									_panelLogin.show();

									var cookieInfo = JSON.parse(cookieValue);

									_loginEmail.html(cookieInfo.email);
									_loginLetter.html(cookieInfo.letter);
									_loginHdrStrong.html(cookieInfo.name);

									loginUserMailInput.val(cookieInfo.email);

								
								} else {

									

								}

							}
					});	

				/*----------------------------------------------------------------------------------------------------
				Card
				----------------------------------------------------------------------------------------------------*/
					$(".card__media--video").hover(function () {
						$(this).find('video')[0].play();
						$(this).find('img').hide();
					 }, function () {
						var el = $(this).find('video')[0],
							img = $(this).find('img');
						
						el.pause();
						el.currentTime = 0;
						img.fadeIn();

					});
				
				/*----------------------------------------------------------------------------------------------------
				Mobx
				----------------------------------------------------------------------------------------------------*/
					var globalMobx = $.ModuloBox({
						mediaSelector 		: '.video-mobx, .img-mobx, .gallery-mobx, .blocks-gallery-grid a, .wp-block-image a, .gallery__bottom a, .section__gallery a, .work__gallery a, .gallery__list a',
						videoAutoPlay 		: true,
						videoThumbnail 		: false,
						controls 			: [ 'play', 'close'],
						counterMessage		: '[index] / [total]',
						loop 				: 3,
						preload 			: 3,
						history 			: true,
						minZoom 			: 1,
						zoomTo 				: 1.8,
						prevNextKey 		: true,
						mouseWheel 			: false,
						contextMenu 		: false,
						doubleTapToZoom 	: true,
						scrollToZoom 		: true,
						captionSmallDevice 	: false,
						escapeToClose 		: true,
						scrollToClose 		: false,
						pinchToClose 		: true,
						dragToClose 		: true,
						tapToClose 			: true,
						scrollToNav 		: false,
						scrollBar 			: true
					});
					globalMobx.init();


				/*----------------------------------------------------------------------------------------------------
				Cookie && Alert
				----------------------------------------------------------------------------------------------------*/
					if (typeof Cookies !== "undefined") { 
						var in60Minutes = new Date(new Date().getTime() + 60 * 60 * 1000),
							in15Minutes = new Date(new Date().getTime() + 15 * 60 * 1000),
							sevenDays = 7;

						var cookieRealtyflow = 'realtyflow_cookie',
							cookieRealtyflowMsg = $('.rf-cookie-msg'),
							cookieRealtyflowButton = cookieRealtyflowMsg.find('.rf-cookie-msg-btn');


						cookieRealtyflowButton.on('click', function() {
							cookieRealtyflowMsg.removeClass('modal--is-visible');
							cookieRealtyflowMsg.hide();
							Cookies.set( cookieRealtyflow, 'hidden', { expires: sevenDays, path: '/' } );
						});

						if( 'hidden' !== Cookies.get( cookieRealtyflow ) ){

							cookieRealtyflowMsg.addClass('modal--is-visible');

						} else {

							cookieRealtyflowMsg.hide();
							cookieRealtyflowMsg.removeClass('modal--is-visible');
							
						}

					
					}
				
				/*----------------------------------------------------------------------------------------------------
				Slider
				----------------------------------------------------------------------------------------------------*/
					if ($.fn.slick) {
						$('.testimonials__slider').slick({
							arrows: true,
							dots: false,
							pauseOnFocus: true,
							pauseOnHover:true,
							autoplay: false,
							autoplaySpeed: 4000,
							nextArrow: $('.slider__btn--next'),
							prevArrow: $('.slider__btn--prev')
						});
					}
				

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},

		// Homepage
		'home': {
			init: function() {
				
				// JavaScript to be fired on the home page
				 
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// Single
		'single' : {
			init: function(){
				
				
			}
		},
		// Page
		'page' : {
			init: function(){
				
			}
		},
		// About
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		},
		'single_portfolio': {
			init: function() {
				// JavaScript to be fired on the about us page
				
				var target = window.location.hash;
				
				if(target){

					window.location.hash = target;
					
					//$('html, body').animate({scrollTop: $(target).offset().top}, 1000, {passive: true});

					// Actualiza el select con la opción correspondiente
					$('.jump-select option[value="' + target + '"]').prop('selected', true);

					// Actualiza los radio buttons con el botón correspondiente
					$('input[name="jump-radio"][value="' + target + '"]').prop('checked', true);

					// Actualiza la lista de enlaces para marcar el enlace correspondiente
					$('.jump-list a[href="' + target + '"]').addClass('active');

				}

				// Escucha el cambio en el select
				$('.jump-select').on('change', function() {
					target = $(this).val();
					window.location.hash = target;
					updateElements();
				});
				  
				// Escucha el cambio en los radio buttons
				$('input[name="jump-radio"]').on('change', function() {
					target = $(this).val();
					window.location.hash = target;
					updateElements();
				});
				  
				// Escucha el clic en la lista de enlaces
				$('.jump-list a').on('click', function(e) {
					e.preventDefault();
					target = $(this).attr('href');
					window.location.hash = target;
					updateElements();
				});

				// Función para actualizar los elementos
				function updateElements() {
					// Actualiza el select con la opción correspondiente
					$('.jump-select option[value="' + target + '"]').prop('selected', true);
					// Actualiza los radio buttons con el botón correspondiente
					$('input[name="jump-radio"][value="' + target + '"]').prop('checked', true);
					// Actualiza la lista de enlaces para marcar el enlace correspondiente
					$('.jump-list a').removeClass('active');
					$('.jump-list a[href="' + target + '"]').addClass('active');
				}
				
				$(window).scroll(function() {
					var entryEnd = $('.entry-content').offset().top + $('.entry-content').outerHeight();	

					if ($(window).scrollTop() + $(window).height() >= entryEnd) {
					
						// Quitar clase "within-entry" a .jump-list-wrapper
						$('.jump-list-wrapper').removeClass("within-entry");
						// Agregar clase "entry-end" a .jump-list-wrapper
						$('.jump-list-wrapper').addClass("entry-end");

					} else {
						
						// Quitar clase "entry-end" a .jump-list-wrapper
						$('.jump-list-wrapper').removeClass("entry-end");

						var entryStart = $('.entry-content').offset().top;
						if ($(window).scrollTop() + 100 >= entryStart) {
							// Agregar clase "within-entry" a .jump-list-wrapper
							$('.jump-list-wrapper').addClass("within-entry");
						} else {
							// Quitar clase "within-entry" a .jump-list-wrapper
							$('.jump-list-wrapper').removeClass("within-entry");
						}
					}

				});

				

				/*
				$('.jump-select').on('change', function() {
					var target = $(this).val();
					$('html, body').animate({
						scrollTop: $(target).offset().top
					}, 1000);
				});
				*/

				/*
				document.querySelector('.jump-select').addEventListener('change', function(event) {
					let target = event.target.value;
					window.scroll({
						top: document.querySelector(`${target}`).offsetTop,
						behavior: 'smooth'
					});
				}, { passive: true });
				*/
			}
		},
		// Service Area 
		'page_template_template_service_area' :{
			init: function(){
				

				var map,
					radius = 15000,
					center = {lat: 51.048615, lng: -114.070846};
				

				
				map = new google.maps.Map(document.getElementById('areaMap'), {
					zoom: 8,
					center: center
				});

				var circle = new google.maps.Circle({
					strokeColor: '#FF0000',
					strokeOpacity: 0.8,
					strokeWeight: 2,
					fillColor: '#FF0000',
					fillOpacity: 0.35,
					map: map,
					center: center,
					radius: radius
				});

				

				/*
				$("#zip-form").submit(function(e) {
					e.preventDefault();

					var zipCode 	= $("#zip-code").val();
					var origin 	= {lat: 51.0447, lng: -114.0719};
					var apiKey 	= 'AIzaSyDYSRye0T54sVodJh8BjTTh-7BlMAdIj3U';
					var destination;
					// Use the Google Maps API to convert the zip code to latitude and longitude
					//AIzaSyAo_hU2zqz2Fri-e55zmL7iM6SSrELTNM0 == marvin
					//AIzaSyDJGHIvmuryGgxM1n0euFOFsJWNVu9-IsM == local + spiga
					//AIzaSyDYSRye0T54sVodJh8BjTTh-7BlMAdIj3U == public marvin
					$.getJSON(`https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${apiKey}`,
						function (data) {
							var destination = data.results[0].geometry.location;
							var distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(origin,destination);
							var distanceInKm = Math.round(distanceInMeters / 1000);
							var cost = (distanceInKm * 2).toFixed(2);
							$("#result").text(`${distanceInKm} km * $2 = $ ${cost.toString().replace(".", ",")} `);
						}
				    );
				});
				*/

				

				

				var map,
					radius = 15000,
					center = {lat: 51.048615, lng: -114.070846};
				
				map = new google.maps.Map(document.getElementById('areaMap'), {
					zoom: 8,
					center: center
				});

				var circle = new google.maps.Circle({
					strokeColor: '#FF0000',
					strokeOpacity: 0.8,
					strokeWeight: 2,
					fillColor: '#FF0000',
					fillOpacity: 0.35,
					map: map,
					center: center,
					radius: radius
				});


				var directionsService = new google.maps.DirectionsService;
				var directionsDisplay = new google.maps.DirectionsRenderer;
			
				$('#zip-form').submit(function(e) {
					e.preventDefault();
					var postalCode 	= $('#zip-code').val(),
						result 		= $('#distance_result'),
						resultInner = result.find('div'),
						calgary 	= new google.maps.LatLng(51.0486, -114.0708),
						geocoder 	= new google.maps.Geocoder();
				
					
					directionsDisplay.setMap(map);

					geocoder.geocode({
						'address': postalCode
					}, function(results, status) {
						if (status === 'OK') {

							var location 	= results[0].geometry.location,
								distance 	= google.maps.geometry.spherical.computeDistanceBetween(calgary, location),
								km 		 	= Math.round(distance / 1000),
								cost 	 	= (km > 15) ? ( (km - 15) * 2).toFixed(2) : 0,
								short_name 	= results[0].address_components[1].short_name,
								long_name 	= results[0].address_components[1].long_name;


							//console.log(results[0].address_components);

							result.show();
							resultInner.addClass('msg--success');
							resultInner.removeClass('msg--error msg--warning');

							var format_km 	= $.number( km ),
								format_cost = $.number( cost );

							$('#distance').val(format_km);
							$('#cost').val(format_cost);

							if (cost == 0) {
								
								resultInner.html('The location <span class="loc_name">'+long_name+'</span> is within <strong class="loc_km">15km</strong> of our headquarters, in this case, no additional cost applies. The distance from our headquarters to the <span class="loc_name">'+long_name+'</span> is around: <strong class="loc_km">' + format_km + 'km</strong>');

							} else {

								if(km > 1000){
									
									resultInner.addClass('msg--warning');
									resultInner.removeClass('msg--error msg--success');

									resultInner.html('The distance from our headquarters to <span class="loc_name">'+long_name+'</span> is a bit far, around <strong class="loc_km">' + format_km + 'km</strong> an approximate budget to go to that location would be <strong class="loc_price">$' + format_cost + '</strong>');

								} else {

									resultInner.addClass('msg--success');
									resultInner.removeClass('msg--error msg--warning');

									resultInner.html('The distance from our headquarters to <span class="loc_name">'+long_name+'</span> is around <strong class="loc_km">' + format_km + 'km</strong> the approximate budget is <strong class="loc_price">$' + format_cost + '</strong>');

								}

								

							}


							directionsService.route({
								origin: calgary,
								destination: location,
								travelMode: 'DRIVING'
							}, function(response, status) {
								if (status === 'OK') {

									directionsDisplay.setDirections(response);

								} else {
									
									if (status === 'ZERO_RESULTS') {
										//console.log("Estás fuera del continente");
										map.setCenter(calgary);
										map.setZoom(8);
										directionsDisplay.setMap(null);
										resultInner.removeClass('msg--success msg--error');
										resultInner.addClass('msg--warning');
										resultInner.html('You’re <strong class="km">' + format_km + 'km</strong> away in <span class="loc_name">'+long_name+'</span>. For now we can not reach that point.');

									} else {
										console.log('Error: ' + status);
									}
								}
							});
							


						} else {

							result.show();
							resultInner.removeClass('msg--success msg--warning');
							resultInner.addClass('msg--error');
							resultInner.html('Invalid Postal Code');


						}   
					});
				});
				

				

				
				
			}

		},
		
		// Login
		'login' : {
			init: function() {

				//== LOGIN FORM
				$('form[name="loginform"]').on('submit', function(e) {
					e.preventDefault();
					var form 		= $(this),
						_wpnonce 	= form.find('input[name="_wpnonce"]').val(),
					 	username 	= form.find('input[name="log"]').val(),
					 	password 	= form.find('input[name="pwd"]').val(),
					 	rememberme 	= form.find('input[name="rememberme"]').is(':checked'),
					 	submitBtn 	= form.find('button[name="wp-submit"]'),
					 	spinner 	= form.find('.spinner');
					
					submitBtn.prop('disabled', true);

					// Mostramos el loader
					spinner.show();
					

					// Enviamos los datos del formulario por Ajax
					$.ajax({
						type: 'POST',
						url: vars.ajax_uri,
						data: {
							action: 'spiga_custom_login',
							username: username,
							password: password,
							rememberme: rememberme,
							security: _wpnonce
						},
						success: function(response) {
							
							spinner.hide();

							if (response.success) {
								form.find('.form-response').html(response.data.message);
								window.location.href = response.data.redirect;
							} else {

								form.find('.form-response').html(response.data.message);

							}
						},
						error: function(xhr, status, error) {

							//console.log(xhr.responseText);
							//console.log(status);
							//console.log(error);
							
							
						},
						complete: function() {
							submitBtn.prop('disabled', false);
						}
					});
				});

				//== REGISTER FORM
				$('form[name="registerform"]').on('submit', function(e) {

					e.preventDefault();
					var form 		= $(this),
						_wpnonce 	= form.find('input[name="_wpnonce"]').val(),
						user_login  = form.find('input[name="user_login"]').val(),
						user_email 	= form.find('input[name="user_email"]').val(),
						first_name 	= form.find('input[name="first_name"]').val(),
						last_name 	= form.find('input[name="last_name"]').val(),
						submitBtn 	= form.find('button[name="wp-submit"]'),
					 	spinner 	= form.find('.spinner');

					 	submitBtn.prop('disabled', true);
					
					// Mostramos el loader
					spinner.show();
					$('[data-msg]').hide();
					// Enviamos los datos del formulario por Ajax
					$.ajax({
						type: 'POST',
						url: vars.ajax_uri,
						data: {
							action: 'spiga_custom_register',
							user_login: user_login,
							user_email: user_email,
							first_name: first_name,
							last_name: last_name,
							security: _wpnonce
						},
						success: function(response) {
							// Escondemos el loader
							spinner.hide();
							if (response.success) {
								form.find('.form-response').html(response.data.message);
								window.location.href = response.data.redirect;
							} else {
								form.find('.form-response').html(response.data.message);
							}
						},
						error: function(xhr, status, error) {

							//console.log('ERROR REGISTER');
							//console.log(xhr.responseText);
							
							
						},
						complete: function() {
							submitBtn.prop('disabled', false);
						}
					});
				});

				//== LOST PASSWORD
				$('form[name="lostpasswordform"]').on('submit', function(e) {
					e.preventDefault();
					var form 		= $(this),
						_wpnonce 	= form.find('input[name="_wpnonce"]').val(),
						user_login 	= form.find('input[name="user_login"]').val(),
						submitBtn 	= form.find('button[name="wp-submit"]'),
						spinner 	= form.find('.spinner');

					submitBtn.prop('disabled', true);

					// Mostramos el loader
					spinner.show();
					
					// enviar la solicitud AJAX
					$.ajax({
						type: 'POST',
						url: vars.ajax_uri,
						data: {
							action: 'spiga_custom_lostpassword',
							user_login: user_login,
							security: _wpnonce
						},
						success: function(response) {
							spinner.hide();
							//console.log(response);
							form.find('.form-response').html(response.data.message);
							form.find('input[name="user_login"]').val('');
							$('[data-msg]').hide();
						},
						error: function(xhr, status, error) {
							
						},
						complete: function() {
							spinner.hide();
							submitBtn.prop('disabled', false);
						}
					});
				});

				//== RESET PASSWORD
				$('form[name="resetpasswordform"]').on('submit', function(e) {
					e.preventDefault();
					var form = $(this),
						_wpnonce 		= form.find('input[name="_wpnonce"]').val(),
						new_password 	= form.find('input[name="new_password"]').val(),
						key 			= form.find('input[name="key"]').val(),
						login 			= form.find('input[name="login"]').val(),
						submitBtn 		= form.find('button[name="wp-submit"]'),
						redirect 		= form.find('input[name="redirect_to"]').val(),
						spinner 		= form.find('.spinner');

					var strength_meter_wrapper	= form.find('.js-password-strength__meter-wrapper'),
						strength_meter_bar 		= form.find('.password-strength__meter'),
						strength_req 			= form.find('.js-password-strength__req');

					submitBtn.prop('disabled', true);

					// Mostramos el loader
					spinner.show();

					// enviar la solicitud AJAX
					$.ajax({
						type: 'POST',
						url: vars.ajax_uri,
						data: {
							action: 'spiga_custom_resetpassword',
							user_login: login,
							new_password: new_password,
							key: key,
							login: login,
							security: _wpnonce
						},
						success: function(response) {
							//console.log(response);
							if (response.success) {

								//console.log('success');
								form.find('.form-response').html(response.data.message);
								$('[data-msg]').hide();
								window.location.href = response.data.redirect;

								/*
								form.find('input[name="new_password"]').val('');
								strength_meter_wrapper.toggleClass('is-hidden');
								strength_meter_bar.css('width','0%');
								strength_req.removeClass('password-strength__req--no-met password-strength__req--met');
								*/
							} else {
								//console.log(response.data);
								//console.log('UNSUCCESSSS 2');
								//console.log(response.data.message);
								form.find('.form-response').html(response.data.message);
							}
							
						},
						error: function(xhr, status, error) {
							//console.log(xhr);
							//console.log(status);
							//console.log(error);
						},
						complete: function() {
							spinner.hide();
							submitBtn.prop('disabled', false);
						}
					});
				});

			}

		},

		// Edit Profile
		'profile': {
			init: function() {
				
				//console.log('form-control--error XXXX');

				if ($.fn.mask) {
					
					$('.birthday').mask('MM/DD/YYYY', {
						'translation': {
							M: {pattern: /[0-9]/},
							D: {pattern: /[0-9]/},
							Y: {pattern: /[0-9]/}
						}
					});
				}
				
				$('form[name="editprofileform"]').on('submit', function(e){
					e.preventDefault();

					var form = $(this),
						_wpnonce = form.find('input[name="_wpnonce"]').val(),
						first_name = form.find('input[name="first_name"]').val(),
						last_name = form.find('input[name="last_name"]').val(),
						user_email = form.find('input[name="user_email"]').val(),
						user_url = form.find('input[name="user_url"]').val(),
						user_phone = form.find('input[name="user_phone"]').val(),
						user_birthday = form.find('input[name="user_birthday"]').val(),
						submitBtn = form.find('button[name="wp-submit"]'),
						spinner = form.find('.spinner');


					//$('.js-alert').hide();
					spinner.show();
					submitBtn.prop('disabled', true);
					//console.log(_wpnonce);
					//console.log(vars.ajax_uri);
					// enviar la solicitud AJAX
					$.ajax({
						type: 'POST',
						url: vars.ajax_uri,
						data: {
							action: 'spiga_custom_update_profile',
							user_email: user_email,
							first_name: first_name,
							last_name: last_name,
							user_url: user_url,
							user_phone: user_phone,
							user_birthday: user_birthday,
							security: _wpnonce
						},
						success: function(response){
							//console.log(response);
							
							//console.log('Llegamos a SUCCESS B');

							form.find('.form-response').html(response.data.message);

							// Agrega la clase .form-control--error a los campos inválidos
							var fields = response.data.fields;
						    if (fields) {
						      $.each(fields.split(','), function(index, value) {
						        $('input[name="' + value + '"]').addClass('form-control--error');
						      });
						    }

						    // Quita la clase .form-control--error de los campos que no están en fields
						    var allFields = form.serializeArray().map(function(field) {
						      return field.name;
						    });
						    var invalidFields = fields ? fields.split(',') : [];
						    var validFields = allFields.filter(function(field) {
						      return !invalidFields.includes(field);
						    });
						    $.each(validFields, function(index, value) {
						      $('input[name="' + value + '"]').removeClass('form-control--error');
						    });

						},
						error: function(response, status, error){
							//console.log('Llegamos a ERROR');
							//console.log(response);
							//console.log(status);
							//console.log(error);
						},
						complete: function() {
							//console.log('Llegamos a COMPLETE B');
							spinner.hide();
							submitBtn.prop('disabled', false);
						}
						
					});
				});
			  
			}
		},

		// Edit Password
		'password': {
			init: function() {

				//== EDIT PASSWORD FORM
				//console.log('edit password v3');
				$('form[name="editpassword"]').on('submit', function(e) {
					e.preventDefault();
					var form 				= $(this),
						_wpnonce 			= form.find('input[name="_wpnonce"]').val(),
						current_password 	= form.find('input[name="current_password"]').val(),
						new_password 		= form.find('input[name="new_password"]').val(),
						confirm_password 	= form.find('input[name="confirm_password"]').val(),
						submitBtn 			= form.find('button[name="wp-submit"]'),
						spinner 			= form.find('.spinner');

					var strength_meter_wrapper	= form.find('.js-password-strength__meter-wrapper'),
						strength_meter_bar 		= form.find('.password-strength__meter'),
						strength_req 			= form.find('.js-password-strength__req');

					submitBtn.prop('disabled', true);
					
					// Mostramos el loader
					spinner.show();
					
					// enviar la solicitud AJAX
					$.ajax({
						type: 'POST',
						url: vars.ajax_uri,
						data: {
							action: 'spiga_custom_update_password',
							current_password: current_password,
							new_password: new_password,
							confirm_password: confirm_password,
							security: _wpnonce
						},
						success: function(response) {
							
							spinner.hide();

							//console.log(response);
							form.find('.form-response').html(response.data.message);

							if (response.success) {

								//console.log('response.success');

								form.find('input[name="current_password"]').val('');
								form.find('input[name="new_password"]').val('');
								form.find('input[name="confirm_password"]').val('');

								strength_meter_wrapper.toggleClass('is-hidden');
								strength_meter_bar.css('width','0%');
								strength_req.removeClass('password-strength__req--no-met password-strength__req--met');

							 } else {

							 	//console.log('response.NOTsuccess');
								//console.log(response);
								

							 }
						},
						error: function(xhr, status, error) {
							//console.log(xhr);
							//console.log(status);
							//console.log(error);
						},
						complete: function() {
							spinner.hide();
							submitBtn.prop('disabled', false);
						
						}
					});
				});
			}
		},

		//== Booking
		'book' : {

			init: function(){

				/*----------------------------------------------------------------------------------------------------
				Stripe
				----------------------------------------------------------------------------------------------------*/
					
					var stripe = Stripe('pk_test_51JJVeyEuSJvUXvDzNh1D1kyZoXOQ4WGWz5qFbHh3vWhnTfCbI9S55o4r5XL9jQWWu9Dq8oEci5oFo6Z2UI0NqrU000KE2JU2oB');

					var elements = stripe.elements();

					

					var addressElement = elements.create('address', {
						mode: 'billing'
					});

					addressElement.mount('#address-element');


					var cardElement = elements.create('card');
					cardElement.mount('#card-element');

				/*----------------------------------------------------------------------------------------------------
				Test Switch
				----------------------------------------------------------------------------------------------------*/
					$('.switchTest').each(function() {
						var _this 	= $(this),
					 		radio 	= _this.find('[name=radioTest]'),
					 		contentA = _this.find('.switchTestA'),
					 		contentB = _this.find('.switchTestB');

					 	
					 	$('input[type=radio][name=radioTest]').on('change', function() {
							switch ($(this).val()) {
								case 'switchTestA':
									
									contentA.show();
									contentB.hide();
									break;
								case 'switchTestB':
									
									contentA.hide();
									contentB.show();
									break;
							}
						});

					});
				
				/*----------------------------------------------------------------------------------------------------
				Summary
				----------------------------------------------------------------------------------------------------
					$("input[data-service]").change(function() {
						var _this = $(this),
							_subtotalCount 		= calculateAll(),
							_servicesCount 		= countServices(),
							_labelAll 			= labelAll(),
							_orderServicesNum 	= $('body').find('.order-services-num'),
							_orderSubtotal 		= $('body').find('.order-subtotal');
		
						_subTotalString 	= _subtotalCount.toString();
						_subTotalNoCents  	= _subTotalString.substring(0,_subTotalString.length - 2);

						_orderServicesNum.text(_servicesCount);
						_orderSubtotal.text(_subtotalCount);



						if ($.fn.priceFormat) {

							_orderSubtotal.priceFormat({
								prefix: '$',
								centsSeparator: '.',
								thousandsSeparator: ',',
								centsLimit: 2
							});
						}
					});*/
				 
				/*----------------------------------------------------------------------------------------------------
				Choice accordion
				----------------------------------------------------------------------------------------------------*/
					$('.choice-accordion').each(function() {
					 	var $this = $(this),
							_toggle 			= $this.find('[data-service]'),
							_select 			= $this.find('.select__input'),
							_multiplier 		= $this.find('.multiplier'),
							_multiplierValue 	= $this.find('.multiplier-value'),
							_selectSelected 	= _select.find(":selected").val();

						_multiplierValue.text(_selectSelected);
						_toggle.change(function() {
							_multiplier.toggle(this.checked);
						}).change();

						_select.change(function() {
							_multiplierValue.text(this.value);
						});
					});
				
			}
		},

		//== Test
		'test' : {
			init: function() {
			
				console.log('test');


				/*----------------------------------------------------------------------------------------------------
				Cart
				----------------------------------------------------------------------------------------------------*/
					// Función para actualizar el resumen y almacenar la información seleccionada en localStorage
					var $serviceItems 	= $('.service_item'),
						$subtotal 		= $('.order-subtotal'),
						$discount 		= $('.order-discount'),
						$total 			= $('.order-total'),
						$numItems 		= $('.order-services-num'),
						$couponInput 	= $('.coupon-code'),
						$applyCouponBtn = $('.coupon-code-apply'),
						$servicesList 	= $('.services-list');


					console.log($couponInput);

					//== Actualiza el sumario de la orden
					function updateSummary() {
						
						console.log('updateSummary');

						var total 			= 0,
							numChecked 		= 0,
							servicesHTML 	= '',
							selectedItems 	= [];

						$('.service_item').each(function() {
							var $checkbox 	= $(this).find('input[type="checkbox"]'),
								$select 	= $(this).find('select'),
								$id 		= $(this).prop('id');
					      
							if ($checkbox.is(':checked')) {
								var title = $(this).find('.service-title').text(),
									price = parseFloat($checkbox.val()) / 100,
									quantity = 1;


								if ($select.length) {
									quantity = parseInt($select.val());
									price = parseFloat(price) * quantity;
									title += ' (x' + quantity + ')';
									// Actualizar el valor del span con la clase .multiplier
									$(this).find('.multiplier').text('(x' + quantity + ')');
								}

								total += parseFloat(price);
								numChecked++;
								servicesHTML += '<li>' + title + ' - ' + '$' + price.toFixed(2) + '</li>';

								selectedItems.push({
									id: $id,
									title: title,
									price: price,
									quantity: quantity
								});
							}
						});

						if(total > 0){
							$('.cart-items').css("display","flex");
						} else {
							$('.cart-items').hide();
						}

						// Actualizar el HTML del resumen
						$subtotal.text('$' + parseFloat(total).toFixed(2));
						$subtotal.attr('data-value', parseFloat(total).toFixed(2));
						/*
						$subtotal.text(total);
						$subtotal.priceFormat({
							prefix: '$',
							thousandsSeparator: ',',
							centsLimit: 2
						});
						*/
						$numItems.text(numChecked);
						$servicesList.html(servicesHTML);
						localStorage.setItem('selectedItems', JSON.stringify(selectedItems));
						applyCoupon();
					}

					//== Aplica el cupón de descuento
					function applyCoupon() {

						var subtotal 	= parseFloat($subtotal.attr('data-value')),
							discount 	= 0,
							coupon 		= $couponInput.val().toUpperCase();

						if (subtotal > 0) {
							// Comprobar si el cupón es válido
							if (coupon === 'DESC10') {

								if (subtotal > 0) {


									// Calcular el descuento del 10% sobre el subtotal
									discount = subtotal * 0.1;
									var discountPercent = (discount / subtotal * 100);

									// Mostrar el código de cupón aplicado y ocultar el div promo-code
									$('.calculate').show();
									$('.promo-code').hide();
									$('.applied-code .code').text(coupon + ' (' + discountPercent + '%)');
									$('.applied-code').show();

									// Guardar el código de cupón en el almacenamiento local
									localStorage.setItem('couponCode', coupon);

									console.log('applyCoupon' + discountPercent);

								} else {

									$discount.text('-$0.00');
									$total.text('$0.00');
									$('.applied-code .code').text(coupon + ' (0%)');

								}
							
							} else {
								
								// Mostrar el div promo-code si el código no es válido
								$('.calculate').hide();
								$('.promo-code').show();
								$('.applied-code').hide();

								// Eliminar el código de cupón del almacenamiento local si no es válido
								localStorage.removeItem('couponCode');
								var discountPercent = 0; // Establecer en 0 si el código no es válido

								console.log('nose a plica ni madres' + discountPercent);
							
							}

							// Actualizar el valor de descuento y total
							$discount.text('- $' + discount.toFixed(2) + ' (' + discountPercent + '%)');
							$total.text('$' + (subtotal - discount).toFixed(2));
						} else {
							console.log('no hay nada para hacer descuento');
						}
					}
					

					// Manejar el evento click del botón de aplicar cupón
					$applyCouponBtn.each(function() {
						var _this = $(this);
						_this.on('click', function(e) {
							e.preventDefault(); // Evitar que se envíe el formulario
							applyCoupon(); // Aplicar el cupón
						});
					});
					// Manejar el evento keypress en el input coupon-code

					$couponInput.each(function() {
						var _this = $(this);
						_this.on('keypress', function(e) {
							if (e.which === 13) { // Comprobar si se presionó la tecla Enter
								e.preventDefault(); // Evitar que se envíe el formulario
								applyCoupon(); // Aplicar el cupón
							}
						});
					});


					$('.remove-coupon').on('click', function() {
						// Restablecer los valores y mostrar el div promo-code
						$couponInput.val('');
						$('.promo-code').show();
						$('.applied-code').hide();
						applyCoupon();
					});

					// Actualizar el resumen cada vez que se cambie un checkbox o select
					$serviceItems.find('input[type="checkbox"]').on('change', function() {
						updateSummary();
						applyCoupon();
					});
					$serviceItems.find('select').on('change', function() {
						updateSummary();
						applyCoupon();
					});


					// Restaura los elementos almacenados en localStorage
					function restoreSelectedItems() {
						var selectedItems = JSON.parse(localStorage.getItem('selectedItems'));

						if (selectedItems !== null && selectedItems.length > 0) {
							for (var i = 0; i < selectedItems.length; i++) {
								var item = selectedItems[i],
									$serviceItem = $('#' + item.id),
									$checkbox = $serviceItem.find('input[type="checkbox"]'),
									$choiceBtn = $serviceItem.find('.choice_control'),
									$choiceAccordionBtn 	= $serviceItem.find('.choice_acc_control_btn'),
									$choiceAccordionPanel 	= $serviceItem.find('.choice_acc_control_panel'),
									$choiceAccordionSelect 	= $serviceItem.find('.choice_acc_control_select');

								$checkbox.prop('checked', true);

								if ($choiceBtn.length > 0) {
									$choiceBtn.addClass('choice-btn--checked');
								}

								if ($choiceAccordionBtn.length > 0) {
									$choiceAccordionBtn.addClass('choice-accordion__btn--checked');
									$choiceAccordionPanel.removeClass('is-hidden');
									$choiceAccordionSelect.val(item.quantity);
								}

								if ($checkbox.is('.choice-btn__input--qty')) {
									$serviceItem.find('.choice-btn__input--qty').val(item.quantity);
								}
							}

							// Restaurar el código de cupón
							var couponCode = localStorage.getItem('couponCode');
							$couponInput.val(couponCode);

							// Actualizar el resumen y aplicar el cupón
							updateSummary();
							applyCoupon();

						}
					}

					// Restaurar los elementos seleccionados al cargar la página
					restoreSelectedItems();


				
					//== DATES
					var $scheduleDate1 	= $("#schedule--date--01"),
						$scheduleTime1 	= $("#schedule--time--01"),
						$scheduleDate2 	= $("#schedule--date--02"),
						$scheduleTime2 	= $("#schedule--time--02"),
						$cartDates 		= $(".cart-dates");

					
					function actualizarCartDates() {

						 // Verificar si algún campo de fecha y hora tiene un valor
						if ($scheduleDate1.pickadate("picker").get("value") !== '' || $scheduleTime1.pickatime("picker").get("value") !== '' || $scheduleDate2.pickadate("picker").get("value") !== '' || $scheduleTime2.pickatime("picker").get("value") !== '') {
							$cartDates.css("display","flex");
						} else {
							$cartDates.hide();
						}

						var dateHoursOne = '<span class="cart-date">'+$scheduleDate1.pickadate("picker").get("value") + '</span><span class="color-contrast-medium marign-left-auto">' + $scheduleTime1.pickatime("picker").get("value")+'</span>';
						var dateHoursTwo = '<span class="cart-date">'+$scheduleDate2.pickadate("picker").get("value") + '</span><span class="color-contrast-medium marign-left-auto">' + $scheduleTime2.pickatime("picker").get("value")+'</span>';					

						$('.dateOne').html(dateHoursOne);
						$('.dateTwo').html(dateHoursTwo);
					}

					// Llamar a la función una vez al cargar la página para reflejar los valores iniciales
					actualizarCartDates();

					// Actualizar cuando se seleccione una fecha o hora
					$scheduleDate1.on("change", actualizarCartDates);
					$scheduleTime1.on("change", actualizarCartDates);
					$scheduleDate2.on("change", actualizarCartDates);
					$scheduleTime2.on("change", actualizarCartDates);


	
					

					/*
					// Crea un elemento de tarjeta de crédito y lo monta en el contenedor 'card-element'
					var cardElement = elements.create('card');
					cardElement.mount('#card-element');

					// Agrega un controlador de eventos al botón 'Pagar' para enviar el formulario de pago
					var submitButton = document.getElementById('submit-payment');
					submitButton.addEventListener('click', function(ev) {
					    ev.preventDefault();

					    // Ejecuta la operación 'createPaymentIntent' en tu servidor para crear un intento de pago
					    fetch('/create-payment-intent', {
					      method: 'POST',
					      headers: {
					        'Content-Type': 'application/json'
					      },
					      body: JSON.stringify({
					        payment_method_types: ['card']
					      })
					    })
					    .then(function(response) {
					      return response.json();
					    })
					    .then(function(result) {
					      // Confirma la tarjeta de crédito con el elemento 'card' de Stripe y envía el pago
					      stripe.confirmCardPayment(result.client_secret, {
					        payment_method: {
					          card: cardElement,
					          billing_details: {
					            name: 'John Doe',
					            address: {
					              line1: '123 Main Street',
					              city: 'San Francisco',
					              state: 'CA',
					              country: 'US',
					              postal_code: '94111'
					            }
					          }
					        }
					      })
					      .then(function(result) {
					        if (result.error) {
					          // Muestra un error si la confirmación del pago falla
					          document.getElementById('payment-result').innerHTML = '<p>' + result.error.message + '</p>';
					        } else {
					          // Muestra el resultado del pago si la confirmación del pago tiene éxito
					          document.getElementById('payment-result').innerHTML = '<p>Pago exitoso</p>';
					        }
					      });
					    });
					  });
					*/

				/*----------------------------------------------------------------------------------------------------
				Choices
				----------------------------------------------------------------------------------------------------*/
				// Agregar event listener al checkbox
				$('.choice-accordion__item').each(function() {

					var _this = $(this),
						_checkBox = _this.find('input[type="checkbox"]'),
						accordionItem = _checkBox.closest('.choice-accordion');

					if (_checkBox.prop('checked')) {
						accordionItem.addClass('choice-accordion__item--checked');
					}

					_checkBox.on('change', function() {
						// Buscar elemento superior más cercano con la clase .choice-accordion__item
						var accordionItem = $(this).closest('.choice-accordion');
						if (accordionItem) {
							// Agregar o quitar la clase panel-active-rf según el estado del checkbox
							if ($(this).prop('checked')) {
								accordionItem.addClass('choice-accordion__item--checked');
							} else {
								accordionItem.removeClass('choice-accordion__item--checked');
							}
						}
					});
				});

				/*----------------------------------------------------------------------------------------------------
				Stripe
				----------------------------------------------------------------------------------------------------*/
					
					var stripe = Stripe('pk_test_51JJVeyEuSJvUXvDzNh1D1kyZoXOQ4WGWz5qFbHh3vWhnTfCbI9S55o4r5XL9jQWWu9Dq8oEci5oFo6Z2UI0NqrU000KE2JU2oB');

					var elements = stripe.elements();

					

					var addressElement = elements.create('address', {
						mode: 'billing'
					});

					addressElement.mount('#address-element');


					var cardElement = elements.create('card');
					cardElement.mount('#card-element');

			}
		}
		

	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');
			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});
			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};
	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
