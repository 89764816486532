(function ($) {
	// EXTEND
	
	//console.log('forms-phone.js');
	
	/*-----------------------------------------------------
	Phone
	------------------------------------------------------*/
	$.fn.extend({
		spPhone: function(){
			var $this = $(this),
				_defaultCountry 		= $this.attr('data-country-default') ? $this.attr('data-country-default') : 'ca',
				_initialCountry 		= $this.attr('data-country-initial') ? $this.attr('data-country-initial') : 'ca',
				_allowExtensions 		= $this.attr('data-allow-extensions') ? $this.attr('data-allow-extensions') : false,
				_autoFormat 			= $this.attr('data-autoformat') ? $this.attr('data-autoformat') : true,
				_allowExtensions 		= $this.attr('data-allow-extensions') ? $this.attr('data-allow-extensions') : false,
				_autoHideDialogCode 	= $this.attr('data-autohide-dialog') ? $this.attr('data-autohide-dialog') : true,
				_autoPlaceholder 		= $this.attr('data-auto-placeholder') ? $this.attr('data-auto-placeholder') : true,
				_nationalMode 			= $this.attr('data-national-mode') ? $this.attr('data-national-mode') : false;
							
			$this.intlTelInput({
				allowExtensions: _allowExtensions,
				autoFormat: _autoFormat,
				autoHideDialCode: _autoHideDialogCode,
				autoPlaceholder: _autoPlaceholder,
				defaultCountry: _defaultCountry,
				initialCountry: _initialCountry,
				customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
					return "e.g. " + selectedCountryPlaceholder;
				},
				geoIpLookup: function(callback) {
					$.get('http://ipinfo.io', function() {}, "jsonp").always(function(resp) {
						var countryCode = (resp && resp.country) ? resp.country : "";
						callback(countryCode);
					});
				},
				nationalMode: _nationalMode,
				numberType: 'MOBILE',
				onlyCountries: ['ca','us','mx'],
				utilsScript: vars.uri+'/assets/js/utils.js'
			});
		}
	});


	//END EXTEND
})(jQuery);